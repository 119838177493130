import React, { FC } from 'react';
/* eslint-disable */
import {
  HeaderKey,
  isMoveOutLessThanLED
} from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';

import { helpRequestStyles } from './styles';
// @ts-ignore
import checkCircle from '../../../../assets/imgs/requestTypes/check_circle.png';
// @ts-ignore
import closeCircle from '../../../../assets/imgs/requestTypes/close_circle.png';
import dayjs from 'dayjs';
import InfoToolTip from '../../../../shared/tooltip/InfoTooltip';
import { CircularProgress } from '@mui/material';

interface ProcessMoveOutContentProps {
  isResolved: boolean;
  isRequiredDaysNoticeMet: boolean;
  tableBody: Record<HeaderKey, string>;
  resolveProcessMoveOutQuery: any;
  selectedButtonIndex: number;
  idx: number;
  handleResolve: () => void;
}

const ProcessMoveOutContent: FC<ProcessMoveOutContentProps> = ({
  tableBody,
  isResolved,
  isRequiredDaysNoticeMet,
  idx,
  resolveProcessMoveOutQuery,
  selectedButtonIndex,
  handleResolve
}) => {
  return (
    <div>
      <TableContainer sx={{ marginBottom: '24px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px', width: '355px' }}>
                Submitted By
              </TableCell>
              <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px', width: '40%' }}>
                Submitted On
              </TableCell>
              <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px' }}>
                Move Out Date
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ borderBottom: 0, padding: '8px 8px 0px 8px', fontSize: '16px' }}>
                {tableBody['submittedBy']}
              </TableCell>
              <TableCell sx={{ borderBottom: 0, padding: '8px 8px 0px 8px', fontSize: '16px' }}>
                {dayjs(tableBody['submittedOn']).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  padding: '8px 8px 0px 8px',
                  fontSize: '16px',
                  color: isMoveOutLessThanLED(tableBody['moveOutDate'], tableBody['leaseEndDate'])
                    ? '#C62828'
                    : '#31343A',
                  fontWeight: isMoveOutLessThanLED(
                    tableBody['moveOutDate'],
                    tableBody['leaseEndDate']
                  )
                    ? 'bold'
                    : 'normal'
                }}>
                {tableBody['moveOutDate']}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ marginBottom: '24px' }}>
        <Table sx={{ minWidth: 650, textAlign: 'left' }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px', width: '355px' }}>
                Primary Reason for Moving Out
              </TableCell>
              <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px', width: '40%' }}>
                Number of Days Notice
              </TableCell>
              <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px' }}>
                Lease End Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ borderBottom: 0, padding: '8px 8px 0px 8px', fontSize: '16px' }}>
                <>{tableBody['primaryReasonMovingOut']}</>
              </TableCell>
              <TableCell sx={{ borderBottom: 0, padding: '8px 8px 0px 8px', fontSize: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {tableBody['daysNotice']}{' '}
                  <div>
                    {isRequiredDaysNoticeMet ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ paddingLeft: '5px', display: 'flex', alignItems: 'center' }}>
                          <InfoToolTip
                            position={'bottom'}
                            icon={checkCircle}
                            widthIcon={13}
                            text={
                              <div style={{ color: '#31343A', fontWeight: 'bold' }}>
                                Required days notice was met
                              </div>
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div style={{ paddingLeft: '5px', display: 'flex', alignItems: 'center' }}>
                          <InfoToolTip
                            position={'bottom'}
                            icon={closeCircle}
                            widthIcon={13}
                            text={
                              <div style={{ color: '#31343A', fontWeight: 'bold' }}>
                                Required days notice was not met
                              </div>
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell sx={{ borderBottom: 0, padding: '8px 8px 0px 8px', fontSize: '16px' }}>
                {tableBody['leaseEndDate']}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ paddingTop: '2%', paddingLeft: '1%', width: '95%' }}>
        <p style={{ fontWeight: 700, fontSize: '14px' }}>Feedback/Comments</p>
        <p style={{ fontSize: '16px' }}>{tableBody['feedback']}</p>
      </div>

      <div style={{ paddingTop: '2%', paddingLeft: '1%' }}>
        <p style={{ fontWeight: 700, fontSize: '14px' }}>Resolution</p>
        <p style={{ margin: 0 }}>
          The resident submitted a Notice to Vacate. It was automatically processed in MRI.
        </p>
        <ul style={{ marginTop: 0, lineHeight: '24px' }}>
          <li>Print move-out acknowledgement in MRI.</li>
          <li>Schedule pre move out inspection task in SightPlan.</li>
          <ul style={{ listStyle: 'disc' }}>
            <li>
              Workspace = <strong>Inspection</strong>
            </li>
            <li>
              Category = <strong>Complete Pre Move Out Inspection</strong>
            </li>
            <li>
              Toggle on <strong>Resident Reported</strong>
            </li>
          </ul>
          <li>Break down resident’s file.</li>
          <li>Resolve this help request.</li>
        </ul>
      </div>

      {isResolved && (
        <TableContainer sx={{ marginBottom: '0px' }}>
          <Table sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px', width: '18.8%' }}>
                  Resolved by
                </TableCell>
                <TableCell sx={{ fontWeight: '700', padding: '8px 8px 0px 8px', width: '40%' }}>
                  Date Time Resolved
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ borderBottom: 0, padding: '8px 8px 0px 8px', fontSize: '16px' }}>
                  <>{tableBody['resolvedBy']}</>
                </TableCell>
                <TableCell sx={{ borderBottom: 0, padding: '8px 8px 0px 8px', fontSize: '16px' }}>
                  {tableBody['dateTimeResolved']}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1% 0%' }}>
        {!isResolved && (
          <>
            {resolveProcessMoveOutQuery.isFetching && selectedButtonIndex === idx ? (
              <Button
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                style={helpRequestStyles.DisabledButton}
                disabled={true}>
                <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: 'capitalize', marginLeft: '16px' }}
                style={helpRequestStyles.PrimaryButton}
                onClick={handleResolve}>
                <p style={{ fontSize: '16px', color: '#FFFFFF', fontFamily: 'ProximaNova' }}>
                  Resolve
                </p>
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProcessMoveOutContent;
