import dayjs from 'dayjs';

interface NTVFlags {
  isTransferWithinThirtyMiles: boolean;
  isWithOfferToChangeLeaseEndDate: boolean;
  isTransferToOtherAvalonBayCommunity: boolean;
  isLeaseEndDateMinusMoveOutDateGreaterThan31: boolean;
  isMoveOutDateEqualLeaseEndDate: boolean;
  isMoveOutDateGreaterThanLeaseEndDate: boolean;
  isRemainRentResponsible: boolean;
  isSubmittedDateMinusOccupancyDateGreaterThan30: boolean;
  isEarlyTermCommunity: boolean;
  vacateAutomated: boolean;
  earlyTerminationFee: string;
  isMoveOutDateMinusSubmittedDateGreaterThan30: boolean;
  daysNotice: number;
  requiredDaysNoticeMet: number;
}

function enforcedSubmittedDay(submittedOn: string) {
  const submittedDay = new Date(submittedOn);

  return dayjs(submittedDay).add(30, 'day').format('MM/DD/YYYY');
}

function isGreenNoticeDays(ntvFlags: NTVFlags): boolean {
  return ntvFlags?.daysNotice - ntvFlags?.requiredDaysNoticeMet >= 0 && !enforced30days(ntvFlags);
}

function parseFeeToNumber(fee = ''): number {
  const convertedString = fee?.replace(/[$,]/g, '');
  const parsedToNumber = Number(convertedString);

  return parsedToNumber;
}

function transferWith30Miles(ntvFlags: NTVFlags): boolean {
  return [
    ntvFlags?.isTransferToOtherAvalonBayCommunity,
    ntvFlags?.isTransferWithinThirtyMiles
  ].every((item) => item === true);
}

function transferGreaterThan30Miles(ntvFlags: NTVFlags): boolean {
  return [
    ntvFlags?.isTransferToOtherAvalonBayCommunity,
    !ntvFlags?.isTransferWithinThirtyMiles
  ].every((item) => item === true);
}

function acceleratedRent(ntvFlags: NTVFlags): boolean {
  return acceleratedRentWithNullFee(ntvFlags) || acceleratedRentWithPositiveFee(ntvFlags);
}

function acceleratedRentWithPositiveFee(ntvFlags: NTVFlags) {
  return [
    parseFeeToNumber(ntvFlags?.earlyTerminationFee) >= 1,
    ntvFlags?.isRemainRentResponsible,
    ntvFlags?.isLeaseEndDateMinusMoveOutDateGreaterThan31
  ].every((item) => item === true);
}

function acceleratedRentWithNullFee({
  isRemainRentResponsible,
  isLeaseEndDateMinusMoveOutDateGreaterThan31,
  earlyTerminationFee
}: NTVFlags) {
  return [
    !earlyTerminationFee,
    isRemainRentResponsible,
    !isLeaseEndDateMinusMoveOutDateGreaterThan31
  ].every((item) => item === true);
}

function thirtyDaysGuarantee({
  isMoveOutDateMinusSubmittedDateGreaterThan30,
  isSubmittedDateMinusOccupancyDateGreaterThan30
}: NTVFlags) {
  return [
    !isMoveOutDateMinusSubmittedDateGreaterThan30,
    !isSubmittedDateMinusOccupancyDateGreaterThan30
  ].every((item) => item === true);
}

function offerToChangeLeaseEndDate(ntvFlags: NTVFlags): boolean {
  if (ntvFlags.isEarlyTermCommunity) {
    return [
      parseFeeToNumber(ntvFlags?.earlyTerminationFee) >= 1,
      ntvFlags?.isLeaseEndDateMinusMoveOutDateGreaterThan31 ||
        !ntvFlags?.isLeaseEndDateMinusMoveOutDateGreaterThan31,
      ntvFlags?.isWithOfferToChangeLeaseEndDate
    ].every((item) => item === true);
  }

  return false;
}

function modLessThanLed(ntvFlags: NTVFlags, vacateAutomated: boolean): boolean {
  if (vacateAutomated) {
    return ntvFlags.isMoveOutDateGreaterThanLeaseEndDate;
  }

  return false;
}

function moveOutDateEqualLeaseEndDate({ isMoveOutDateEqualLeaseEndDate }: NTVFlags): boolean {
  return isMoveOutDateEqualLeaseEndDate;
}

function moveOutDateGreaterLeaseEndDate({
  isMoveOutDateGreaterThanLeaseEndDate
}: NTVFlags): boolean {
  return isMoveOutDateGreaterThanLeaseEndDate;
}

function moveOutDateLessThanLeaseEndDateNotLive(ntvFlags: NTVFlags): boolean {
  if (!ntvFlags.isEarlyTermCommunity) {
    return (
      !ntvFlags.isMoveOutDateGreaterThanLeaseEndDate && !ntvFlags.isMoveOutDateEqualLeaseEndDate
    );
  }

  return false;
}

function enforced30days(ntvFlags: NTVFlags) {
  return ntvFlags?.isWithOfferToChangeLeaseEndDate && ntvFlags?.daysNotice < 30;
}

function showScreen(arrayOfFlags: boolean[], shouldBeDisplayed: boolean): boolean {
  if (!shouldBeDisplayed) {
    return arrayOfFlags.every((flag) => flag === true);
  }

  return true;
}

export {
  showScreen,
  transferWith30Miles,
  transferGreaterThan30Miles,
  acceleratedRentWithPositiveFee,
  acceleratedRentWithNullFee,
  thirtyDaysGuarantee,
  offerToChangeLeaseEndDate,
  moveOutDateEqualLeaseEndDate,
  moveOutDateGreaterLeaseEndDate,
  modLessThanLed,
  isGreenNoticeDays,
  moveOutDateLessThanLeaseEndDateNotLive,
  acceleratedRent,
  enforced30days,
  enforcedSubmittedDay
};
