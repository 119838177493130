function isStandardHelpRequest(helpRequestArr: [string]) {
  if (helpRequestArr && helpRequestArr.length > 0) {
    return (
      !helpRequestArr?.includes('process-move-out') ||
      !helpRequestArr?.includes('window-guard') ||
      !helpRequestArr?.includes('lease-modification')
    );
  }

  return false;
}

export default isStandardHelpRequest;
