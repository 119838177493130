/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box } from '@mui/material';

import NoticeToVacateMRI from './NoticeToVacateMRI';
import NoticeToVacateInfo from './NoticeToVacateInfo';
import isStandardHelpRequest from '../../../../utils/isHelpRequest';

const NoticeToVacateContent = ({
  tableBody,
  resolveNoticeToVacateQuery,
  handleResolve,
  ntvRequestType,
  isResolved,
  selectedButtonIndex,
  ntvFlags,
  isRequiredDaysNoticeMet,
  helpRequestTypes,
  idx
}: any) => {
  return (
    <div>
      {ntvRequestType?.includes('notice-to-vacate') && (
        <NoticeToVacateInfo
          tableBody={tableBody}
          isResolved={isResolved}
          isRequiredDaysNoticeMet={isRequiredDaysNoticeMet}
          ntvFlags={ntvFlags}
          idx={idx}
        />
      )}
      {isResolved && !isStandardHelpRequest(helpRequestTypes) ? (
        <NoticeToVacateInfo
          isResolved={isResolved}
          tableBody={tableBody}
          isRequiredDaysNoticeMet={isRequiredDaysNoticeMet}
          ntvFlags={ntvFlags}
          idx={idx}
        />
      ) : (
        <Box component="div" sx={{ p: '0 8px' }}>
          <NoticeToVacateMRI
            tableBody={tableBody}
            resolveNoticeToVacateQuery={resolveNoticeToVacateQuery}
            handleResolve={handleResolve}
            selectedButtonIndex={selectedButtonIndex}
            idx={idx}
          />
        </Box>
      )}
    </div>
  );
};

export default NoticeToVacateContent;
